// First we need to import axios.js
import axios from 'axios';
import {BASE_URL, LOGGED_IN} from "./Constants";

const instance = axios.create({

    withCredentials: true,
    //baseURL:BASE_URL
});

instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if (error.response.status === 401) {
        // DELETE YOUR AUTHENTICATE_USER item from localStorage
        localStorage.removeItem(LOGGED_IN)
    }
    return Promise.reject(error);
});

export default instance;