/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {fetchCode, postReading} from "./actions/actions";
import {ActionTypes} from "./Constants";
import classd from 'classd'
import {useNavigate, useParams} from "react-router-dom";

const initialState = {
    v:{},
    post: null,
    qrcode: {},
    txtcolor: "#1D376D",
    bgcolor: "#F36F21",
    inputList: [{c: "", h: ""}],
    appt_number: "",
    loading: false,
    sending: false,
    errorMessage: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_CODE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_CODE_SUCCESS: {
            let v = {...state.v}
            v.name = action.name;


            return {
                ...state,
                v: v,
                post: action.post,
                loading: false
            }
        }
        case ActionTypes.GET_CODE_FAILURE: {
            localStorage.clear()

            return {
                ...state,
                v:{},
                post:null,
                loading: false
            }
        }
        case ActionTypes.POST_READING_REQUEST: {
            return {
                ...state,
                sending: true
            }
        }
        case ActionTypes.POST_READING_SUCCESS: {
            return {
                ...state,
                sending: false
            }
        }

        case 'SET_VALUES': {
            let p = {...state.v};
            p.code = action.code;
            p.name = action.name;

            return {
                ...state,
                v: p,
                post:action.post
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.v};
            c[action.name] = action.value
            return {
                ...state,
                v: c,
                loading: false
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        }

        default:
            return state;
    }
};



const setError = (errorMessage) => {
    return {
        type: 'SET_ERROR',
        errorMessage
    }
}

const setValues = (code, name, post) => {
    return {
        type: 'SET_VALUES',
        code,name,post
    }
}

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const ReadingEnter = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {v, loading, errorMessage, posts, bgcolor, post,  sending} = state;
    let navigate = useNavigate();
    let {code} = useParams()
    useEffect(() => {
        console.log('useEffect called');
        // check in localstorage
        let lcode = localStorage.getItem("code");
        let fetchRemote  = false;
        if (lcode) {
            if (code !== lcode) {
                fetchRemote = true;
            } else {

                let lname = localStorage.getItem("name");
                let lpost = localStorage.getItem("post");
                let ldate = localStorage.getItem("date");
                let mss = Number(ldate);
                let d = new Date().getTime();
                let dateDiff = d - mss
                const thirtyDays = 1000 * 60 * 24 * 30;
                if (dateDiff < thirtyDays) {
                    dispatch(setValues(lcode, lname, lpost));
                } else {
                    fetchRemote = true;
                }
            }
        } else {
            fetchRemote = true;
        }
        if (fetchRemote) {
            fetchCode(dispatch, code).then(response => {


                }
            ).catch(error => {
                navigate(`/failure`);

            });

        }
    }, []);


    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('send');

        if (!v.house) {
            dispatch(setError("Хаалга/тоотоо оруулна уу!"));
            return;
        }

        if (!v.appt) {
            dispatch(setError("Хаалга/тоотоо оруулна уу!"));
            return;
        }
        if (!v.readings) {
            dispatch(setError("Усны заалтуудаа оруулна уу!"));
            return;
        }
        let info = {};
        info.code = code;
        info.house = v.house;
        info.appt = v.appt;
        info.readings = v.readings;

        postReading(dispatch, info).then(response => {
                console.log(response);
                navigate("/success");
            }
        ).catch(error => {
            alert(error)
        });

    };


    return (
        <div>
            <div className="pt-5 pb-3 px-4 text-center text-2xl font-bold">
                <span className="text-gray-600">{v.name}</span>
            </div>
            <div className="border p-5 m-2 my-5 text-center text-sm font-normal">
                <span className="text-black">{post}</span>
            </div>

            <div className="group m-4 md:m-32">
                <h1 className="mt-8  text-xl font-medium text-gray-600">Усны заалтаа өгөх</h1>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="group">

                        <div className="border group mt-8">
                            <label className="pl-2 text-blue-400" htmlFor="house">Байр</label>
                            <input
                                className="px-2 py-2 w-full font-bold focus:outline-none focus:border-transparent"
                                name="house"
                                type="text"
                                value={v.house}
                                onChange={handleChangeField}
                                placeholder="Байрны дугаар оруулах"/>
                        </div>

                        <div className="border group mt-8">
                            <label className="pl-2 text-blue-400" htmlFor="appt">Хаалга/Тоот</label>
                            <input
                                className="px-2 py-2 w-full font-bold focus:outline-none focus:border-transparent"
                                name="appt"
                                type="text"
                                value={v.appt}
                                onChange={handleChangeField}
                                placeholder="Хаалга/тоотын дугаар оруулах"/>
                        </div>

                        <div className="border group mt-8">
                            <label className="pl-2 text-blue-400" htmlFor="readings">Тоолуурын заалт</label>
                            <textarea
                                className="px-2 py-2 w-full font-bold focus:outline-none focus:border-transparent"
                                name="readings"
                                value={v.readings}
                                onChange={handleChangeField}
                                placeholder="Тоолуурын заалтаа оруулах"/>
                        </div>

                        <button
                            className="inline-flex items-center w-full md:w-1/2 mt-8 px-4 py-3 border border-transparent text-xl leading-6 font-medium text-white bg-pink-600 hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:shadow-outline-indigo active:bg-pink-700 transition ease-in-out duration-150"
                            type="submit">
                            {sending &&
                            <svg className='animate-spin h-5 w-5 mr-3' viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            }
                            <span className={classd`${{hidden: !sending, inline: sending}}`}>Илгээж байна.</span>
                            <span className={classd`${{hidden: sending, inline: !sending}}`}>Илгээх</span>

                        </button>
                        {errorMessage &&
                        <div style={{margin: "5px"}}><span
                            style={{color: "red", fontSize: "smaller"}}>{errorMessage}</span></div>
                        }

                    </div>
                </form>
            </div>



        </div>
    )
}


export default ReadingEnter;