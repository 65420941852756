/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {fetchCode, postCode} from "./actions/actions";
import {ActionTypes} from "./Constants";
import classd from 'classd'
import {useNavigate} from "react-router-dom";

const initialState = {
    code: null,
    txtcolor: "#1D376D",
    bgcolor: "#F36F21",
    sending: false,
    errorMessage: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.POST_CODE_REQUEST: {
            return {
                ...state,
                sending: true
            }
        }
        case ActionTypes.POST_CODE_SUCCESS: {

            return {
                ...state,
                sending: false
            }
        }
        case ActionTypes.POST_CODE_FAILURE: {
            return {
                ...state,
                sending: false,
                errorMessage: "Код олдсонгүй"
            }
        }

        case ActionTypes.GET_CODE_REQUEST: {
            return {
                ...state,
                sending: true
            }
        }
        case ActionTypes.GET_CODE_SUCCESS: {

            return {
                ...state,
                sending: false
            }
        }
        case ActionTypes.GET_CODE_FAILURE: {
            return {
                ...state,
                sending: false,
                errorMessage: "Код олдсонгүй"
            }
        }

        case 'SET_VALUES': {
            let p = {...state.v};
            p.code = action.code;
            p.name = action.name;
            return {
                ...state,
                v: p
            }
        }
        case 'CHANGE_FIELD': {
            return {
                ...state,
                code: action.value,
                errorMessage: null
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        }

        default:
            return state;
    }
};


const setError = (errorMessage) => {
    return {
        type: 'SET_ERROR',
        errorMessage
    }
}

const setValues = (code, name) => {
    return {
        type: 'SET_VALUES',
        code, name
    }
}

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const ReadingHome = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {code, loading, errorMessage, posts, bgcolor, post, sending} = state;
    let navigate = useNavigate();

    useEffect(() => {
        console.log('useEffect called');
        // check in localstorage
        let cd = localStorage.getItem("code");
        let dt = localStorage.getItem("date");

        let ms = Date.parse(dt);
        let d = new Date().getMilliseconds();
    }, []);


    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('send');

        if (!code) {
            dispatch(setError("Конторын кодоо оруулна уу!"));
            return;
        }

        let lcode = localStorage.getItem("code");
        let postRemote = false;

        if (lcode && lcode === code) {
            let lname = localStorage.getItem("name");
            let lpost = localStorage.getItem("post");
            let ldate = localStorage.getItem("date");
            let mss = Number(ldate);
            let d = new Date().getTime();
            let dateDiff = d - mss
            const thirtyDays = 1000 * 60 * 24 * 30;
            if (dateDiff < thirtyDays) {
                navigate(`${code}`);
            } else {
                postRemote = true;
            }
        } else {
            postRemote = true;
        }
        if (postRemote) {
            fetchCode(dispatch, code).then(response => {
                    console.log(response);
                    navigate(`${code}`);
                }
            ).catch(error => {
                //alert(error)
            });
        }

    };


    return (

        <div className="group m-8 md:m-32">
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="group">

                    <div className="border group mt-8">
                        <label className="pl-2 text-blue-400" htmlFor="code">Конторын код</label>
                        <input
                            className="px-2 py-2 w-full font-bold  focus:outline-none focus:border-transparent"
                            name="code"
                            type="text"
                            value={code}
                            onChange={handleChangeField}
                            placeholder="Конторын код оруулах"/>
                    </div>
                    {errorMessage &&
                        <div style={{margin: "5px"}}><span
                            style={{color: "red", fontSize: "smaller"}}>{errorMessage}</span></div>
                    }
                    <div>
                        <button
                            className="inline-flex text-center items-center w-full md:w-1/2 mt-8 px-4 py-3 border border-transparent text-xl leading-6 font-medium text-white bg-rose-600 hover:bg-rose-500 focus:outline-none focus:border-rose-700 focus:shadow-outline-indigo active:bg-rose-700 transition ease-in-out duration-150"
                            type="submit">
                            {sending &&
                                <svg className='animate-spin h-5 w-5 mr-3' viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            }
                            <span className={classd`${{hidden: !sending, inline: sending}}`}>Илгээж байна.</span>
                            <span className={classd`${{hidden: sending, inline: !sending}}`}>Илгээх</span>

                        </button>

                    </div>

                </div>
            </form>
        </div>
    )
}


export default ReadingHome;