/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";


const ReadingSuccess = () => {

    return (
        <div>


            <div className="group m-8 md:m-32 text-center">
                <p className="mt-8  text-xl font-medium">Амжилттай илгээлээ.</p>
                <p>Усны заалтаа хугацаанд нь илгээсэнд баярлалаа.</p>
            </div>


        </div>
    )
}


export default ReadingSuccess;