/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect} from "react";
import history from "./history";
import {Outlet} from "react-router-dom";
import packageJson from "../package.json";


const Layout = () => {

    return (
        <div>
            <header className="h-16 bg-rose-800 flex">
                <div className="">
                    <span className="text-white text-lg p-3 align-middle">Manaibair.com</span>
                </div>

            </header>
            <Outlet />
            <footer>
                <div className="bg-gray-100 p-6 mt-48">
                    <p>&copy; 2020-2023 Manaibair.com Strong Neighbours, {packageJson.version}</p>
                </div>
            </footer>
        </div>
    )

}


export default Layout




