/**
 * Created by gantushig on 1/30/16.
 */

import keyMirror from 'keymirror';


//export const BASE_URL= '/';
//DEV
//export const BASE_URL= 'http://localhost:3028/';

//PROD
export const BASE_URL= 'https://api.manaibair.com/';


//export const BASE_URL= 'https://api2.gs1mongolia.org/';
//export const BASE_URL= 'http://172.104.86.125:3000/';
//export const BASE_URL= 'http://gs1api.inextmon.com/';
//export const BASE_URL= 'http://192.168.1.4:8090/';
//export const BASE_URL= 'http://192.168.1.121:8090/';
//export const BASE_URL= 'http://192.168.1.6:8090/';
//export const BASE_URL= 'http://192.168.0.101:8090/';
//export const BASE_URL= 'http://192.168.0.102:8090/';
//export const BASE_URL= 'http://192.168.8.2:3000/';
//export const BASE_URL= 'http://192.168.8.4:8090/';


export const TOKEN = "xyoni_token"
export const LOGGED_IN = "logged_in";
export const LOGGED_EMAIL = "logged_email";

export const ActionTypes =  keyMirror({

    FAILURE:null,

    LOGIN:null,
    LOGIN_REQUEST:null,
    LOGIN_SUCCESS:null,
    LOGIN_FAILURE:null,
    VERIFY_FAILURE:null,
    LOGOUT:null,

    SIGNUP_REQUEST:null,
    SIGNUP_SUCCESS:null,
    SIGNUP_FAILURE:null,

    LOGOUT_REQUEST:null,
    LOGOUT_SUCCESS:null,
    LOGOUT_FAILURE:null,

    UPLOAD_BARCODE_REQUEST:null,
    UPLOAD_BARCODE_SUCCESS:null,
    UPLOAD_BARCODE_FAILURE:null,

    DELETE_BARCODE_REQUEST:null,
    DELETE_BARCODE_SUCCESS:null,
    DELETE_BARCODE_FAILURE:null,

    LOAD_CODES_REQUEST:null,
    LOAD_CODES_SUCCESS:null,
    LOAD_CODES_FAILURE:null,

    GET_CODE_REQUEST:null,
    GET_CODE_SUCCESS:null,
    GET_CODE_FAILURE:null,

    GET_ORDER_REQUEST:null,
    GET_ORDER_SUCCESS:null,
    GET_ORDER_FAILURE:null,

    CONFIRM_EMAIL_REQUEST:null,
    CONFIRM_EMAIL_SUCCESS:null,
    CONFIRM_EMAIL_FAILURE:null,

    CHECK_USER_TRIAL_REQUEST:null,
    CHECK_USER_TRIAL_SUCCESS:null,
    CHECK_USER_TRIAL_FAILURE:null,

    START_USER_TRIAL_REQUEST:null,
    START_USER_TRIAL_SUCCESS:null,
    START_USER_TRIAL_FAILURE:null,

    GET_ACTIVE_CODE_COUNT_REQUEST:null,
    GET_ACTIVE_CODE_COUNT_SUCCESS:null,
    GET_ACTIVE_CODE_COUNT_FAILURE:null,

    GET_CODE_STYLING_REQUEST:null,
    GET_CODE_STYLING_SUCCESS:null,
    GET_CODE_STYLING_FAILURE:null,

    GET_PRODUCT_IMAGE_REQUEST:null,
    GET_PRODUCT_IMAGE_SUCCESS:null,
    GET_PRODUCT_IMAGE_FAILURE:null,

    GET_ORDERS_REQUEST:null,
    GET_ORDERS_SUCCESS:null,
    GET_ORDERS_FAILURE:null,

    RESET_PASSWORD_REQUEST:null,
    RESET_PASSWORD_SUCCESS:null,
    RESET_PASSWORD_FAILURE:null,

    STORE_PASSWORD_REQUEST:null,
    STORE_PASSWORD_SUCCESS:null,
    STORE_PASSWORD_FAILURE:null,

    CREATE_ORDER_REQUEST:null,
    CREATE_ORDER_SUCCESS:null,
    CREATE_ORDER_FAILURE:null,

    DELETE_ORDER_REQUEST:null,
    DELETE_ORDER_SUCCESS:null,
    DELETE_ORDER_FAILURE:null,

    UPLOAD_PRODUCT_IMAGE_REQUEST:null,
    UPLOAD_PRODUCT_IMAGE_SUCCESS:null,
    UPLOAD_PRODUCT_IMAGE_FAILURE:null,

    UPLOAD_LOGO_REQUEST:null,
    UPLOAD_LOGO_SUCCESS:null,
    UPLOAD_LOGO_FAILURE:null,

    POST_READING_REQUEST:null,
    POST_READING_SUCCESS:null,
    POST_READING_FAILURE:null,

    POST_CODE_REQUEST:null,
    POST_CODE_SUCCESS:null,
    POST_CODE_FAILURE:null,

    CREATE_CODE_REQUEST:null,
    CREATE_CODE_SUCCESS:null,
    CREATE_CODE_FAILURE:null,

    DELETE_CODE_REQUEST:null,
    DELETE_CODE_SUCCESS:null,
    DELETE_CODE_FAILURE:null,

    ACTIVATE_CODE_REQUEST:null,
    ACTIVATE_CODE_SUCCESS:null,
    ACTIVATE_CODE_FAILURE:null,

    DEACTIVATE_CODE_REQUEST:null,
    DEACTIVATE_CODE_SUCCESS:null,
    DEACTIVATE_CODE_FAILURE:null,

    UPDATE_CODE_REQUEST:null,
    UPDATE_CODE_SUCCESS:null,
    UPDATE_CODE_FAILURE:null,

    UPDATE_CODE_STYLING_REQUEST:null,
    UPDATE_CODE_STYLING_SUCCESS:null,
    UPDATE_CODE_STYLING_FAILURE:null,

    DELETE_PRODUCT_IMAGE_REQUEST:null,
    DELETE_PRODUCT_IMAGE_SUCCESS:null,
    DELETE_PRODUCT_IMAGE_FAILURE:null,

    DOWNLOAD_BARCODE_REQUEST:null,
    DOWNLOAD_BARCODE_SUCCESS:null,
    DOWNLOAD_BARCODE_FAILURE:null,

    CREATE_PAYMENT_NOTE_REQUEST:null,
    CREATE_PAYMENT_NOTE_SUCCESS:null,
    CREATE_PAYMENT_NOTE_FAILURE:null,

    TOGGLE_MAIN_MENU:null,

    GET_HOUSE_REQUEST:null,
    GET_HOUSE_SUCCESS:null,
    GET_HOUSE_FAILURE:null,

});



export const Endpoints  =  {
    ENTER_FROM_QRCODE:'/api/v1.0/reading-qrcode',
    HOUSE_BY_KEY: '/api/v1.0/house/key',
    HOUSE: '/api/v1.0/house',
    SIGNUP_URL:'/api/signup',
    LOGIN_URL:'/api/login',
    LOGOUTV1_URL:'/api/v1.0/logout',

    QRCODES:'/api/v1.0/qrcodes',
    CODE: BASE_URL +'api/v1.0/code',
    CODES: BASE_URL +'api/v1.0/codes',
    QRCODE: '/api/v1.0/qrcode',
    CODE_STYLING: '/api/v1.0/qrcode-styling',
    CODE_LOGO: '/api/v1.0/qrcode-logo',
    PDF:  '/api/v1.0/pdf',

    ORDERS:'/api/v1.0/orders',
    ORDER: '/api/v1.0/order',
    PAYMENT_NOTE:'/api/v1.0/payment-note',

    USER_TRIAL:'/api/v1.0/user-trial',
    CONFIRM_EMAIL:'/api/v1.0/auth/user/verify',
    RESET_PASSWORD:'/api/v1.0/reset-password',
    CHANGE_PASSWORD:'/api/v1.0/change-password',
}

