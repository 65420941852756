/**
 * Created by gantushig on 4/18/19.
 */

import {ActionTypes, Endpoints} from '../Constants';
import axios from '../axiosConfig'


export const fetchCodeRequest = () => {
    return {
        type: ActionTypes.GET_CODE_REQUEST
    }
};
export const fetchCodeSuccess = (name, post) => {
    return {
        type: ActionTypes.GET_CODE_SUCCESS,
        name,
        post
    }
};

export const fetchCodeFailure = () => {
    return {
        type: ActionTypes.GET_CODE_FAILURE
    }
};

export const fetchCode = (dispatch, code) => {
    return new Promise(function (resolve, reject) {

    dispatch(fetchCodeRequest());
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.CODE}/${code}`;
    config["method"] = "get";

    axios(config)
        .then(response => {
            localStorage.setItem("code", code);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("post", response.data.post);
            localStorage.setItem("date", (new Date()).getTime());
            dispatch(fetchCodeSuccess( response.data.name, response.data.post))
            resolve(response);
        })
        .catch(
            err => {
                dispatch(fetchCodeFailure())
                reject(err);
            }
        )
    });
}

const postReadingRequest = () => {
    return {
        type: ActionTypes.POST_READING_REQUEST
    }
};

const postReadingSuccess = () => {
    return {
        type: ActionTypes.POST_READING_SUCCESS

    }
};
export const postReading = (dispatch, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(postReadingRequest())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.CODES}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(postReadingSuccess())
                resolve(response);
            })
            .catch(
                err => {
                    reject(err);
                }
            )
    });

}



const postCodeRequest = () => {
    return {
        type: ActionTypes.POST_CODE_REQUEST
    }
};

const postCodeSuccess = (code, name, post) => {
    return {
        type: ActionTypes.POST_CODE_SUCCESS,
        code,
        name,
        post
    }
};

const postCodeFailure = () => {
    return {
        type: ActionTypes.POST_CODE_FAILURE

    }
};

export const postCode = (dispatch, code) => {

    return new Promise(function (resolve, reject) {

        dispatch(postCodeRequest())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.CODE}/code`;
        config["method"] = "post";
        let info = {}
        info.code = code;
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                localStorage.setItem("code", code);
                localStorage.setItem("name", response.data.name);
                localStorage.setItem("post", response.data.post);
                localStorage.setItem("date", (new Date()).getTime());

                dispatch(postCodeSuccess(code, response.data.name, response.data.post))
                resolve(response);
            })
            .catch(
                err => {
                    dispatch(postCodeFailure())
                    reject(err);
                }
            )
    });

}
