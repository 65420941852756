/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";


const ReadingFailure = () => {

    return (
        <div>


            <div className="group m-8 md:m-32 text-center">
                <p className="mt-8  text-xl font-medium">Код олдсонгүй.</p>
                <p>Кодоо сайн нягтлаад дахин илгээнэ үү.</p>
            </div>


        </div>
    )
}


export default ReadingFailure;